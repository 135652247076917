import { React, useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import '../../pages/Style.scss';
import { useNavigate } from 'react-router-dom';
import { MaarketerReportListingAction, ManagerReportListingAction } from '../../redux/Slices/CampaignSlice';
import { Pagination } from '../../components/Hooks/Pagination';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { DownloadFileInNewWindow } from '../../utils/Function';
import { Button } from '../../components/Common/Button';

function Report() {
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState(1);
    const [listDataArr, setListDataArr] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [searchString, setSearchString] = useState("")
    const [downloadLoader, setDownloadLoader] = useState(false)

    useEffect(() => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
        }
        if (selectedTab == 1) {
            ManagerReportListingAction(payload, (response) => {
                if (response?.status === true) {
                    setListDataArr(response)
                }
            })();
        }
        if (selectedTab == 2) {
            MaarketerReportListingAction(payload, (response) => {
                if (response?.status === true) {
                    setListDataArr(response)
                }
            })();
        }
    }, [ManagerReportListingAction, MaarketerReportListingAction, selectedTab, searchString, currentPage, perPageCount])

    const handleSelectedTab = (key) => {
        setSelectedTab(key)
    }

    const handleDownloadExcel = () => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
            download: "csv"
        }
        setDownloadLoader(true)
        if (selectedTab == 1) {
            ManagerReportListingAction(payload, (response) => {
                if (response?.status === true) {
                    DownloadFileInNewWindow(response?.data?.url)
                }
                setDownloadLoader(false)
            })();
        }
        if (selectedTab == 2) {
            MaarketerReportListingAction(payload, (response) => {
                if (response?.status === true) {
                    DownloadFileInNewWindow(response?.data?.url)
                }
                setDownloadLoader(false)
            })();
        }
    }

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Report</h1>
                <Button
                    className="btn_blue"
                    text="Download Excel"
                    type="submit"
                    onClick={handleDownloadExcel}
                    loader={downloadLoader}
                    disabled={downloadLoader}
                />
            </div>

            <div className='tab_section_card'>
                <div className='common_tab_section'>
                    <Tabs defaultActiveKey={selectedTab} id="uncontrolled-tab-example" onSelect={handleSelectedTab}>
                        <Tab eventKey={1} title="Manager Report">
                            <div className='request_campaigns_list'>
                                <div className='content_campaigns_user table-responsive'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Campaign Name</th>
                                                <th>No. of markets Joined</th>
                                                <th>Product type </th>
                                                <th>Tags </th>
                                                <th>Target link</th>
                                                <th>PPC rate</th>
                                                <th>Remaining Credits</th>
                                                <th>Total Paid </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listDataArr?.data?.own_campaign_list?.length > 0 ?
                                                    listDataArr?.data?.own_campaign_list?.map((value, index) => (
                                                        <tr>
                                                            <td> {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + (index + 1)}. </td>
                                                            <td> {value?.campaign_name ? value?.campaign_name : "-"} </td>
                                                            <td> {value?.no_of_marketers_join} </td>
                                                            <td> {value?.product_type ? value?.product_type : "-"} </td>
                                                            <td>
                                                                <p className="tags_line_table">
                                                                    {value?.tags ?
                                                                        JSON.parse(value?.tags)?.map((data, index) => (
                                                                            index < 2 ?
                                                                                <span> {data} </span>
                                                                                :
                                                                                index == 3 ? <span> +{JSON.parse(value?.tags).length - 2} </span>
                                                                                    :
                                                                                    null
                                                                        ))
                                                                        : ""
                                                                    }
                                                                </p>
                                                            </td>
                                                            <td className='target_link_table'>
                                                                <a href={value?.target_link} className='text-ellipsis' target='_blank'> {value?.target_link} </a>
                                                            </td>
                                                            <td> {value?.ppc_rate ? parseFloat(value?.ppc_rate?.toFixed(2)) : "-"} </td>
                                                            <td className='text-center'>${value?.remaining_credits ?  parseFloat(value?.remaining_credits?.toFixed(2)) : 0}</td>
                                                            <td className='text-center'>${value?.total_ppc}</td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan={9}>
                                                            <NoDataFound />
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <div className='pagination_entries_section'>
                                        <div className='entries_text'>
                                            Showing {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + 1} of {listDataArr?.data?.pagination?.totalItems} entries
                                        </div>

                                        {listDataArr?.data?.own_campaign_list?.length > 0 ?
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={listDataArr?.data?.pagination?.totalItems}
                                                pageSize={listDataArr?.data?.pagination?.perPage}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey={2} title="Marketer Report">
                            <div className='request_campaigns_list'>
                                <div className='content_campaigns_user table-responsive'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                {/* <th>To date</th>
                                                <th>From date</th> */}
                                                {/* <th>Total revenue </th> */}
                                                <th>Manager name</th>
                                                <th>campaign name</th>
                                                {/* <th>Total joined Campaigns  </th> */}
                                                <th>Product type</th>
                                                <th>Tags</th>
                                                <th>Target link</th>
                                                <th>Remaining Credits </th>
                                                <th>PPC rate</th>
                                                <th>Total Clicks</th>
                                                <th>Credits Earned </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listDataArr?.data?.marketer_join_camp_list?.length > 0 ?
                                                    listDataArr?.data?.marketer_join_camp_list?.map((value, index) => (
                                                        <tr key={index}>
                                                            <td> {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + (index + 1)}. </td>
                                                            <td> {value?.manager_name ? value?.manager_name : ""}</td>
                                                            <td> {value?.campaign_name ? value?.campaign_name : "-"} </td>
                                                            {/* <td> {value?.campaign_name ? value?.campaign_name : "-"} </td> */}
                                                            <td> {value?.product_type ? value?.product_type : "-"} </td>
                                                            <td>
                                                                <p className="tags_line_table">
                                                                    {value?.tags ?
                                                                        JSON.parse(value?.tags)?.map((data, index) => (
                                                                            index < 2 ?
                                                                                <span> {data} </span>
                                                                                :
                                                                                index == 3 ? <span> +{JSON.parse(value?.tags).length - 2} </span>
                                                                                    :
                                                                                    null
                                                                        ))
                                                                        : ""
                                                                    }
                                                                </p>
                                                            </td>
                                                            <td className='target_link_table'>
                                                                <a href={value?.target_link} className='text-ellipsis' target='_blank'> {value?.target_link} </a>
                                                            </td>
                                                            <td className='text-center'>${value?.remaining_credits ? parseFloat(value?.remaining_credits?.toFixed(2)) : 0}</td>
                                                            <td> {value?.ppc_rate ?  parseFloat(value?.ppc_rate?.toFixed(2)) : "-"} </td>
                                                            <td> {value?.total_ppc ?  parseFloat(value?.total_ppc?.toFixed(2)) : "-"} </td>
                                                            <td> {value?.earn_credits ? parseFloat(value?.earn_credits?.toFixed(2)) : "-"} </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan={9}>
                                                            <NoDataFound />
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <div className='pagination_entries_section'>
                                        <div className='entries_text'>
                                            Showing {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + 1} of {listDataArr?.data?.pagination?.totalItems} entries
                                        </div>

                                        {listDataArr?.data?.marketer_join_camp_list?.length > 0 ?
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={listDataArr?.data?.pagination?.totalItems}
                                                pageSize={listDataArr?.data?.pagination?.perPage}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </Tab>

                    </Tabs>
                </div>
            </div>

        </>
    )
}

export default Report;